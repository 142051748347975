<template>
  <div class="">
    <p>Configura los Términos y Condiciones de tu servicio.</p>
    <label class="font-bold">Términos y Condiciones</label>

    <EditorWysiwygComponent
      @onEditorChange="onEditorChange"
      :setText.sync="userConfigText.legal"
    />

    <ButtonSaveComponent @onSaveClick="handleSaveClick" />
  </div>
</template>

<script>
import configService from "../../services/configService";
import ButtonSaveComponent from "../../../components/ButtonSave";
import EditorWysiwygComponent from "../../../components/EditorWysiwyg";

export default {
  name: "PreferencesLegalComponent",
  data: () => ({
    userConfigText: {}
  }),
  methods: {
    onEditorChange({ text }) {
      this.userConfigText.legal = text;
    },
    async handleGetUserConfigTexts() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await configService.getUserConfigTexts().finally(() => {
          this.$store.dispatch("toggleRequestLoading");
        });
        if (response.data.data) {
          this.userConfigText = response.data.data;
        }
      } catch (error) {
        return false;
      }
    },
    async handleSaveClick() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await configService
          .updateUserConfigTexts(this.userConfigText)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message, data } = response.data;
        if (success) {
          if (data) this.userConfigText = data;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    ButtonSaveComponent,
    EditorWysiwygComponent
  },
  mounted() {
    this.handleGetUserConfigTexts();
  }
};
</script>

<style>
#textarea-info-legal {
  max-width: 800px;
  padding: 2rem;
}
</style>
