import { render, staticRenderFns } from "./Template.vue?vue&type=template&id=3b09fc58&scoped=true"
import script from "./Template.vue?vue&type=script&lang=js"
export * from "./Template.vue?vue&type=script&lang=js"
import style0 from "./Template.vue?vue&type=style&index=0&id=3b09fc58&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b09fc58",
  null
  
)

export default component.exports