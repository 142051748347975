<template>
  <el-color-picker
    @active-change="selectedColor"
    v-model="color"
    :predefine="predefineColors"
    popper-class="color-picker-template"
  >
  </el-color-picker>
</template>

<script>
export default {
  name: "ColorPickerComponent",
  data() {
    return {
      predefineColors: [
        "#0e0051",
        "#2edeac",
        "#9a0252",
        "#90ee90",
        "#00ced1",
        "#f56c6c",
        "#34485e"
      ]
    };
  },
  methods: {
    selectedColor(color) {
      this.$emit("getColor", { color, part: this.part });
    }
  },
  props: {
    part: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: "#999999"
    }
  }
};
</script>

<style lang="sass">
.color-picker-template
  .el-color-dropdown__btns
    button
      display: none;
</style>
