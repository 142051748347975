<template>
  <div class="">
    <TopBarTitleComponent title="Configuraciones" :showBackIcon="true">
      <template slot="right-area"></template>
    </TopBarTitleComponent>

    <b-container fluid class="pb-4 px-lg-4">
      <b-row>
        <b-col xs="12" sm="12" md="3">
          <h4 class="h4 font-semi-bold">Tus Preferencias</h4>
          <hr />

          <div class="list-content-preferences list-tabs-left">
            <el-tabs
              v-model="activeName"
              :tab-position="tabPosition"
              style="height: 200px;"
              @tab-click="handleTabPreferences"
            >
              <el-tab-pane
                label="Servidor de Correo"
                name="email"
              ></el-tab-pane>
              <el-tab-pane
                label="Personalización de Plantillas"
                name="templates"
              ></el-tab-pane>
              <el-tab-pane label="Mensajes" name="messages"></el-tab-pane>
              <el-tab-pane
                label="Términos y Condiciones"
                name="legal"
              ></el-tab-pane>
            </el-tabs>
          </div>

          <hr />

          <a href="#" @click.prevent="handleShowOnBoard" class="text-muted"
            ><i class="el-icon-question text-color-secondary h3"></i> Ver
            instrucciones</a
          >
        </b-col>
        <b-col xs="12" sm="12" md="9">
          <div class="card box-shadow">
            <div class="card-body">
              <PreferencesMessagesComponent v-if="activeName === 'messages'" />
              <PreferencesTemplateComponent v-if="activeName === 'templates'" />
              <PreferencesEmailComponent v-if="activeName === 'email'" />
              <PreferencesLegalComponent v-if="activeName === 'legal'" />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <OnBoardComponent :show.sync="showOnBoard" v-if="showOnBoard" />
  </div>
</template>

<script>
import TopBarTitleComponent from "../../components/TopBarTitle";
import PreferencesLegalComponent from "./preferences/InformationLegal";
import PreferencesMessagesComponent from "./preferences/Messages";
import PreferencesEmailComponent from "./preferences/Email";
import PreferencesTemplateComponent from "./preferences/Template";
import OnBoardComponent from "../../components/OnBoard";

export default {
  name: "ConfigurationsMainComponent",
  data: () => ({
    tabPosition: "left",
    activeName: "email",
    showOnBoard: false
  }),
  methods: {
    handleTabPreferences(tab) {
      this.activeName = tab.name;
    },
    handleShowOnBoard() {
      this.showOnBoard = !this.showOnBoard;
    }
  },
  components: {
    TopBarTitleComponent,
    PreferencesLegalComponent,
    PreferencesMessagesComponent,
    PreferencesEmailComponent,
    PreferencesTemplateComponent,
    OnBoardComponent
  }
};
</script>

<style lang="scss" scoped></style>
