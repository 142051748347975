<template>
  <div class="">
    <p>
      Configura tu correo para el envio de documentos a tus clientes.
      <strong>
        Debe llenar todos los campos de la configuración y activar el uso
        personalizado SMTP. De lo contrario utilizaremos por defecto la cuenta
        de Mydessk.
      </strong>
    </p>
    <el-form>
      <b-row>
        <b-col xs="12" sm="12" md="6">
          <el-form-item>
            <slot name="label">
              <label class="font-semi-bold m-0"
                >Usuario / Correo electrónico</label
              >
            </slot>
            <el-input type="text" v-model="userConfigSmtp.email"></el-input>
          </el-form-item>
          <el-form-item>
            <slot name="label">
              <label class="font-semi-bold m-0">Contraseña</label>
            </slot>
            <el-input
              type="password"
              v-model="userConfigSmtp.password"
              show-password
            ></el-input>
          </el-form-item>
        </b-col>
        <b-col xs="12" sm="12" md="6">
          <el-form-item>
            <slot name="label">
              <label class="font-semi-bold m-0">Servidor SMTP</label>
            </slot>
            <el-input type="text" v-model="userConfigSmtp.host"></el-input>
          </el-form-item>
          <el-form-item>
            <slot name="label">
              <label class="font-semi-bold m-0">Puerto SMTP</label>
            </slot>
            <el-input type="text" v-model="userConfigSmtp.port"></el-input>
          </el-form-item>
          <el-form-item>
            <slot name="label">
              <label class="font-semi-bold m-0">Encriptación SMTP</label>
            </slot>
            <el-select v-model="userConfigSmtp.encryption">
              <el-option value="tls">tls</el-option>
              <el-option value="ssl">ssl</el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <slot name="label">
              <label class="font-semi-bold m-0">Nombre del Remitente</label>
            </slot>
            <el-input type="text" v-model="userConfigSmtp.from_name"></el-input>
          </el-form-item>
          <el-form-item>
            <slot name="label">
              <label class="font-semi-bold m-0">Correo del Remitente</label>
            </slot>
            <el-input
              type="text"
              v-model="userConfigSmtp.from_email"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <slot name="label">
              <el-switch v-model="userConfigSmtp.active"></el-switch>
              <label class="font-semi-bold m-0 ml-2"
                >Uso de la configuración personalizada de SMTP</label
              >
            </slot>
          </el-form-item>
        </b-col>
      </b-row>
      <ButtonSaveComponent @onSaveClick="handleSaveClick" />
    </el-form>
  </div>
</template>

<script>
import configService from "../../services/configService";
import ButtonSaveComponent from "../../../components/ButtonSave";

export default {
  name: "PreferencesEmailComponent",
  data() {
    return {
      userConfigSmtp: {}
    };
  },
  methods: {
    async handleGetUserConfigSmtp() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await configService.getUserConfigSmtp().finally(() => {
          this.$store.dispatch("toggleRequestLoading");
        });
        const { data } = response.data;
        if (data) {
          this.userConfigSmtp = data;
        }
      } catch (error) {
        return false;
      }
    },
    async handleSaveClick() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await configService
          .updateUserConfigSmtp(this.userConfigSmtp)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    ButtonSaveComponent
  },
  mounted() {
    this.handleGetUserConfigSmtp();
  }
};
</script>

<style lang="scss" scoped></style>
