<template>
  <button class="btn md__btn-primary float-right" @click.prevent="handleClick">
    Guardar
  </button>
</template>

<script>
export default {
  name: "ButtonSaveComponent",

  methods: {
    handleClick() {
      this.$emit("onSaveClick");
    }
  }
};
</script>

<style></style>
