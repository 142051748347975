<template>
  <div class="mb-5">
    <p>
      Configura tus plantillas para el envío de correos a tus clientes. Si
      mantiene los campos vacíos se enviarán las plantillas por defecto de
      Mydessk.
    </p>

    <section
      class="wrapper__user-config-email wrapper__cotent-tabs"
      v-if="userConfigEmail"
    >
      <el-tabs v-model="activeTabName">
        <el-tab-pane label="Documentos" name="documents">
          <b-row>
            <b-col xs="12" sm="12" md="2">
              <label class="mt-4 font-semi-bold d-block mb-2"
                >Color principal</label
              >
              <ColorPickerComponent
                @getColor="onGetColorDocument"
                part="document__background_color"
                :color.sync="document__background_color.backgroundColor"
              />
            </b-col>
            <b-col xs="12" sm="12" md="10">
              <b-row>
                <b-col xs="12" sm="12" md="5">
                  <img
                    :src="userConfigEmail.image"
                    alt="Logo"
                    width="200px"
                    v-if="userConfigEmail.image"
                  />
                </b-col>
                <b-col xs="12" sm="12" md="7">
                  <div
                    class="border-radius bg-color-primary d-flex justify-content-center align-items-center px-4 py-3"
                    :style="document__background_color"
                  >
                    <h3 class="m-0 p-0">Documento</h3>
                  </div>
                  <p class="text-primary m-0 mt-4">
                    <strong>Fecha:</strong>
                    00-00-0000
                  </p>
                  <p class="text-primary m-0">
                    <strong>Documento #:</strong>
                    0000
                  </p>
                </b-col>
              </b-row>
              <b-row class="my-4">
                <b-col xs="12" sm="12">
                  <p class="font-bold text-primary mt-4">Detalle documento</p>
                </b-col>
              </b-row>
              <b-row class="">
                <b-col xs="12" sm="12" md="6">
                  <p class="font-bold text-primary mt-2">Información empresa</p>
                  <div class="border-radius md__border rectangle"></div>
                </b-col>
                <b-col xs="12" sm="12" md="6">
                  <p class="font-bold text-primary mt-2">Información cliente</p>
                  <div class="border-radius md__border rectangle"></div>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col xs="12" sm="12">
                  <p class="font-bold text-primary mt-5">
                    Productos / Servicios
                  </p>
                  <div
                    :style="document__background_color"
                    class="border-radius bg-color-primary d-flex justify-content-center align-items-center p-4"
                  ></div>
                </b-col>
              </b-row>

              <b-row class="mt-5">
                <b-col xs="12" sm="12" md="6"></b-col>
                <b-col xs="12" sm="12" md="6">
                  <div class="bg-color-gray rectangle border-radius"></div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </el-tab-pane>
        <el-tab-pane label="Correos" name="emails">
          <label class="my-2">
            <strong>Logo Cabecera del correo</strong> <br />
            <small class="text-muted">(Logo empresa por defecto)</small>
          </label>
          <b-row>
            <b-col xs="12" sm="12" md="4">
              <FileUploadComponent
                title="Subir Logo"
                :preview="userConfigEmail.image"
                @fileBase64="handleFileBase64"
              />

              <label class="mt-4 font-semi-bold d-block mb-2"
                >Fondo de cabecera</label
              >
              <ColorPickerComponent
                @getColor="onGetColor"
                part="background_header"
                :color.sync="background_header.backgroundColor"
              />

              <label class="mt-4 font-semi-bold d-block mb-2"
                >Texto del Footer</label
              >
              <ColorPickerComponent
                @getColor="onGetColor"
                part="color_footer"
                :color="color_footer.color"
              />

              <label class="mt-4 font-semi-bold d-block mb-2"
                >Fondo del Contenedor</label
              >
              <ColorPickerComponent
                :color.sync="background_container.backgroundColor"
                @getColor="onGetColor"
                part="background_container"
              />
            </b-col>
            <b-col xs="12" sm="12" md="8">
              <div
                class="wrapper__content-template-default"
                style="padding: 2rem;"
                :style="background_container"
              >
                <div
                  class="content-template-default"
                  style="border-radius: 5px; max-width: 500px; display: block; margin: auto; color: #404040; border-radius: 10px 10px 0px 0px;"
                >
                  <div
                    class="template-header"
                    style="text-align: center;padding: 2rem 0; border-radius: 5px 5px 0px 0px;"
                    :style="background_header"
                  >
                    <img
                      :src="userConfigEmail.image"
                      alt="Logo"
                      width="300px"
                      v-if="userConfigEmail.image"
                    />
                  </div>
                  <div
                    class="template-body"
                    style="padding: 2rem 4rem; background-color: #ffffff;border-radius: 0px 0px 5px 5px;"
                  >
                    <p>Hola, [nombre]</p>

                    <p>
                      Soy [nombre], representante de [nombre de la empresa].
                      Somos una compañía que se especializa en [oferta primaria]
                      con el fin de [soluciones a problemas de tus clientes].
                    </p>

                    <p>
                      Sabemos que usted se interesa por conocer más acerca de
                      [tema donde compartió su contacto]. Actualmente, hemos
                      ayudado a nuestros clientes para optimizar su rendimiento
                      en ese rubro, tal como fuese el caso de [testimonios], por
                      medio de [producto].
                    </p>
                  </div>
                </div>

                <div
                  class="template-footer"
                  style="display: flex; justify-content: center; flex-direction: column; align-items: center;margin-top: 2rem;"
                  :style="color_footer"
                >
                  <h2 style="font-size: 2rem;"><LogoComponent /></h2>
                  <small style="margin-top: 10px;"
                    >Este correo fue generado por el cliente que obtuvo</small
                  >
                  <small style="margin-top: 5px;"
                    ><strong
                      >Contáctenos | Términos y Condiciones</strong
                    ></small
                  >
                  <small style="margin: 10px 0px;padding-bottom:10px;"
                    >Todos los derechos Reservados Mydessk</small
                  >
                </div>
              </div>
            </b-col>
          </b-row>
        </el-tab-pane>
      </el-tabs>

      <b-row class="mt-5">
        <b-col xs="12" sm="12" md="12">
          <ButtonSaveComponent @onSaveClick="handleSaveClick" class="mt-2" />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import configService from "../../services/configService";
import ButtonSaveComponent from "../../../components/ButtonSave";
import FileUploadComponent from "../../../components/FileUpload";
import LogoComponent from "@/components/Logo/Logo";
import ColorPickerComponent from "../../../components/ColorPicker";

export default {
  name: "PreferencesTemplateComponent",
  data: () => ({
    activeTabName: "documents",
    userConfigEmail: null,
    imageUpload: null,
    document__background_color: {
      backgroundColor: "#0e0051 !important"
    },
    color_footer: {
      color: "#0e0051"
    },
    background_header: {
      backgroundColor: "#FFF"
    },
    background_container: {
      backgroundColor: "#F2F3F4"
    }
  }),
  methods: {
    async handleGetUserConfigEmail() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await configService.getUserConfigEmail().finally(() => {
          this.$store.dispatch("toggleRequestLoading");
        });
        if (response.data.data) this.handleUpdateData(response.data.data);
      } catch (error) {
        return false;
      }
    },
    async handleSaveClick() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await configService
          .updateUserConfigEmail(this.userConfigEmail)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message, data } = response.data;
        if (success) {
          if (data) this.handleUpdateData(data);
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    onGetColor(data) {
      const { color, part } = data;
      if (part === "color_footer") this[`${part}`].color = color;
      else this[`${part}`].backgroundColor = color;

      this.userConfigEmail[`${part}`] = color;
    },
    onGetColorDocument(data) {
      const { color, part } = data;
      this[`${part}`].backgroundColor = `${color} !important`;
      this.userConfigEmail[`${part}`] = color;
    },
    handleFileBase64(base64) {
      this.userConfigEmail.image = base64;
    },
    handleUpdateData(data) {
      const {
        color_footer,
        background_header,
        background_container,
        document__background_color
      } = data;
      this.userConfigEmail = data;
      this.color_footer.color = color_footer;
      this.background_header.backgroundColor = background_header;
      this.background_container.backgroundColor = background_container;

      this.document__background_color.backgroundColor = document__background_color;
    }
  },
  components: {
    FileUploadComponent,
    LogoComponent,
    ColorPickerComponent,
    ButtonSaveComponent
  },
  mounted() {
    this.handleGetUserConfigEmail();
  }
};
</script>

<style scoped lang="sass">
.content-template-default
  margin-bottom: 2rem;

.rectangle
  height: 100px;
</style>
